<template>
  <div class="guide-list-channels-references">
    <div
      class="reference-next-channels selectable"
      tabindex="-1"
      v-on:click.prevent="channels('down', 7)"
    >
      <span>
        <i class="zmdi zmdi-chevron-down"></i>
      </span>
      <p>Próximos canales</p>
    </div>
    <div
      class="reference-previous-channels selectable"
      tabindex="-1"
      v-on:click.prevent="channels('up', -7)"
    >
      <span>
        <i class="zmdi zmdi-chevron-up"></i>
      </span>
      <p>Canales anteriores</p>
    </div>
    <div
      class="reference-menu selectable"
      tabindex="-1"
      v-on:click.prevent="menu()"
    >
      <span>
        <i class="zmdi zmdi-menu"></i>
      </span>
      <p>Menú</p>
    </div>
    <div
      class="reference-yerterday selectable"
      tabindex="-1"
      v-on:click.prevent="programs('left')"
      :class="{ disabled: disablePrevButton }"
    >
      <span>
        <svg
          width="34"
          height="33"
          viewBox="0 0 34 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M25.4802 25.1804C23.6292 26.4172 21.4757 27.1176 19.2578 27.2093V29.1107C21.8516 29.018 24.3723 28.2058 26.5358 26.7602C28.8381 25.2219 30.6325 23.0354 31.6921 20.4772C32.7517 17.919 33.029 15.1041 32.4888 12.3884C31.9486 9.67264 30.6152 7.17807 28.6573 5.22014C26.6994 3.2622 24.2048 1.92883 21.4891 1.38864C18.7733 0.848448 15.9584 1.1257 13.4002 2.18532C10.8421 3.24495 8.65558 5.03936 7.11724 7.34165C5.67163 9.50515 4.85941 12.0258 4.76674 14.6196H6.66814C6.75985 12.4018 7.46022 10.2482 8.69703 8.39723C10.0266 6.4074 11.9164 4.85651 14.1273 3.94069C16.3383 3.02487 18.7712 2.78525 21.1184 3.25213C23.4656 3.71901 25.6216 4.87143 27.3138 6.56364C29.006 8.25586 30.1584 10.4119 30.6253 12.759C31.0922 15.1062 30.8526 17.5391 29.9367 19.7501C29.0209 21.9611 27.47 23.8508 25.4802 25.1804Z"
            fill="#C9E1F2"
            stroke="#C9E1F2"
          />
          <path
            d="M19.5357 7.61963H17.25V15.3488L23.75 19.3592L24.8214 17.4634L19.5357 14.1821V7.61963Z"
            fill="#C9E1F2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0.00196034 26.1609L7.12696 20.0137L7.12696 23.835L13.2031 23.835L13.2031 28.5598L7.12696 28.5598L7.12696 32.3804L0.00196034 26.1609Z"
            fill="#C9E1F2"
          />
        </svg>
      </span>
      <p>{{ prevButtonText }}</p>
    </div>
    <div
      class="reference-tomorrow selectable"
      tabindex="-1"
      v-on:click.prevent="programs('right')"
      :class="{ disabled: disableNextButton }"
    >
      <span>
        <svg
          width="34"
          height="33"
          viewBox="0 0 34 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.77761 25.4036C9.62862 26.6404 11.7821 27.3407 14 27.4324V29.3338C11.4062 29.2412 8.88552 28.429 6.72202 26.9833C4.41973 25.445 2.62532 23.2585 1.56569 20.7003C0.506066 18.1422 0.22882 15.3272 0.769013 12.6115C1.30921 9.89578 2.64258 7.40122 4.60051 5.44328C6.55845 3.48535 9.05301 2.15198 11.7687 1.61179C14.4845 1.07159 17.2994 1.34884 19.8576 2.40847C22.4157 3.46809 24.6022 5.26251 26.1406 7.5648C27.5862 9.7283 28.3984 12.249 28.4911 14.8428H26.5897C26.498 12.6249 25.7976 10.4714 24.5608 8.62038C23.2312 6.63054 21.3415 5.07966 19.1305 4.16384C16.9195 3.24802 14.4866 3.0084 12.1394 3.47528C9.79224 3.94216 7.63623 5.09457 5.94401 6.78679C4.2518 8.479 3.09939 10.635 2.6325 12.9822C2.16562 15.3294 2.40524 17.7623 3.32106 19.9732C4.23688 22.1842 5.78777 24.074 7.77761 25.4036Z"
            fill="#C9E1F2"
            stroke="#C9E1F2"
          />
          <path
            d="M14.2768 7.84277H16.5625V15.5719L10.0625 19.5824L8.99107 17.6865L14.2768 14.4053V7.84277Z"
            fill="#C9E1F2"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M33.2012 26.0105L26.0762 32.1577V28.3364H20V23.6116H26.0762V19.791L33.2012 26.0105Z"
            fill="#C9E1F2"
          />
        </svg>
      </span>
      <p>{{ nexButtonText }}</p>
    </div>
  </div>
</template>

<script>
const KEY_CODES = {
  ArrowLeft: 37,
  ArrowUp: 38,
  ArrowRight: 39,
  ArrowDown: 40,
  Enter: 13,
};

import * as dateHelper from "@/helpers/dateHelper.js";
import * as guideTradHelpers from "@/helpers/guideTradHelpers.js";

export default {
  name: "GuideTradButtons",
  props: {
    prevProgramButtonText: {
      type: String,
      default: "Ayer",
      required: false,
    },

    nextProgramButtonText: {
      type: String,
      default: "Mañana",
      required: false,
    },

    disableOnLeftSide: {
      type: Boolean,
      default: false,
      required: false,
    },

    disableOnRightSide: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {},
  data() {
    return {
      focusOnChannel: 0,
      time: null,
      focusedProgram: {
        idAsset: null,
        title: "",
        time: "",
        poster: "",
      },
      activatedEvents: false,
      focusOnTime: Date.now(),
      refreshTimeId: null,
      reactiveDetector: 0,
      pixelsInTenMinutes: 0,
      timeLineLeftPixels: 0,
      focusOnProgramIdAsset: 0,
    };
  },
  mounted() {},
  beforeDestroy() {},
  computed: {
    prevButtonText() {
      return this.prevProgramButtonText;
    },
    nexButtonText() {
      return this.nextProgramButtonText;
    },
    disablePrevButton() {
      // TODO: está pendiente la funcionalidad para aceptar mas días
      // if (this.prevProgramButtonText !== 'Ayer' && this.prevProgramButtonText !== 'Hoy') {
      //     return true;
      // } else {
      //     return false;
      // }
      return this.disableOnLeftSide;
    },
    disableNextButton() {
      // TODO: está pendiente la funcionalidad para aceptar mas días
      // if (this.nextProgramButtonText !== 'Mañana' && this.nextProgramButtonText !== 'Hoy') {
      //     return true;
      // } else {
      //     return false;
      // }
      return this.disableOnRightSide;
    },
  },
  methods: {
    channels(direction, count) {
      this.$emit("gtbChannels", direction, count);
    },
    programs(direction) {
      let idx = 0;
      if (direction === "left" && !this.disablePrevButton) {
        idx = -1;
      } else if (direction === "right" && !this.disableNextButton) {
        idx = 1;
      } else {
        return;
      }

      this.$emit("gtbPrograms", idx);
    },
    menu() {
      this.$emit("gtbMenu");
    },
  },
};
</script>
